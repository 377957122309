@import '../../node_modules/bootstrap/scss/bootstrap.scss';

html,
body {
    margin: 0;
}
.layout {
    width: 100%;
    height: 100vh;
    padding: 20px;
    transition: all 0.3s;
    padding:0px;
    top: 0;
    right: 0;
    float: left
}
.app-menu {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background: white;
    transition: all 0.3s;
    /* overflow-y: scroll; */
}
.rojo{
    background-color: red;
}
.app-menu.active {
    left: 0;
}
.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
}

.slot-end{
    float: right;
    margin-left:5px
}

.dynamic-scroll{
    overflow-y: auto;
}
.dynamic-scroll-waiting{
    margin-left: 0;
    margin-right: 0;
    position:fixed;
    width:50px;
    height:50px;
    background-color:grey ;
    border-radius: 10px;
    padding-top:8px;
}
.notify{
    position: absolute;
    display: none;
    width: 300px;
    z-index: 9999;
    background-color: #424242;
    padding: 10px;
    padding-top: 13px;
    padding-bottom: 14px;
    padding-left: 25px;
    border-radius: 4px;
    box-shadow: 13px 1px 30px -8px #434343;
    height:fit-content;
    color: #fafafad9;
}
.notify-message{
    font-size: 16px;
}
.notify-close{
    margin-left:15px;
    margin-top:6px
}

input[type=number],.number{
    text-align: end;
}
td input[type=checkbox]{
    margin-top: 12px;
}

.close{
    cursor: pointer;
}



.table-responsive{
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding-bottom: 0px
}

.bar{
    @extend .row;
    font-size: larger;
    height: 56px;
    background: white;
    color: #777;
    box-shadow: 0px -7px 18px 8px #77777763;

    .bar-node{
        @extend .d-flex, .align-items-center
    }
    
    .bar-icon{
        @extend .bar-node, .col-1, .justify-content-center;
        max-width:fit-content;
    }
    
    .bar-title{
        @extend .bar-node, .col;   
        font-weight: 500;
    }
    

}


.menu-item{
    cursor:pointer
}
.menu-item .icon {
    margin-right: 15px;width: 15px; font-size: 13px;
}

.number{
    @extend .text-right !optional
}

.col-number{
    @extend .number, .align-middle !optional; 
    width:130px
}
.col-mark{
    width:40px;
    text-align: center;    
}

.fit-content{
    max-width: fit-content
}

.table > tbody > tr > td {
    vertical-align: middle;
}

.border-1 {
    @extend .border !optional;
    border-radius: 15px;
}

.flat-input{
    border-radius: 0%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.link {
    @extend .text-primary;
    cursor: pointer;
}

.card-header{
    // height: 50px
}
.card-title{
   margin-bottom: 0px !important
}




.map{
    @extend .border-1,.mb-4;
    height: 200px;
    background-image: url('../media/img/geolocation.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    opacity: 1;

}


.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.marco-camara{
    background-image: url('../media/img/camera.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    cursor: pointer;
}

.top-0{
    top:60px !important
}

.tree{
    padding-top: 15px;
    padding-left: 15px;
    overflow: scroll;
    ul{
        padding-left: 15px;
    }
    .node-tree{
        list-style-type: none;
        margin-left:0px;
        i{
            font-size: 10px;
            margin-right: 10px;
        }
    }
}

.capture{
    position: relative; 
    border-radius: 15px; 
    border: 1px solid #ddd;
    padding: 5px;
    height:100%; 
    img{
        width:100% ; 
        height:100%; 
        // background-image: url('/assets/media/img/camera.png');
        // background-size: 400px; 
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
    }
    .capture-file{
        position: absolute; margin-left: 154px; right: 30px; top: 30px;
        .icon{
            font-size: 30px;
            color: white;
            cursor: pointer;
            opacity: 0.7;
            text-shadow: 0px 2px 13px #999;            
        }
    }
    .capture-camera{
        position: absolute; margin-left: 154px; right: 30px; top: 85px;
        .icon{
            font-size: 30px;
            color: white;
            cursor: pointer;
            opacity: 0.7;
            text-shadow: 0px 2px 13px #999;
        }
    }

}

.text-purple{
    color:purple
}
